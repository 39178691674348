import React from 'react';

const colors = {
  background: '#00AFAA',
  foreground: '#FFF',
  shadow: '#00A09C',
};

const monotoneColors = {
  background: '#FFF',
  foreground: '#111',
  shadow: 'transparent',
};

interface Props {
  monotone?: boolean;
}

const Logo: React.FC<Props> = ({ monotone = false }) => (
  <svg
    viewBox="0 0 137 40"
    fill={monotone ? monotoneColors.background : colors.background}
    width={137}
    height={40}
    style={{ verticalAlign: 'middle' }}
  >
    <g>
      <path d="M39.9912845,19.9949399 L39.9912845,3.06626141 L39.9912845,1.69551821 L39.9912845,1.38710099 C40,1.30571311 39.9912845,1.28001168 39.9912845,1.26287739 C39.96078,0.577505786 39.385554,0.0206413607 38.675237,0.00350707071 L1.363983,0.00350707071 C0.605730472,0.0206413607 0.0174310927,0.586072931 0,1.30142954 L0,20.0077906 L0,36.9407527 L0,38.3286302 L0,38.6156296 L0,38.7398532 C0.0348621854,39.4380755 0.610088245,39.9863728 1.3160475,40.0035071 L38.6316592,40.0035071 C39.3899118,39.9906564 39.9738534,39.4252248 39.9912845,38.7141517 C39.9970948,38.6827389 39.9970948,32.4430016 39.9912845,19.9949399 Z" />
      <path d="M137,19.9935753 L137,3.0667095 L137,1.69182996 L137,1.38772888 L137,1.26351858 C136.966044,0.578220366 136.410012,0.0214155691 135.718155,0 L99.3808074,0 L82.8271742,0.0214155691 C82.7988774,0.0214155691 70.635494,0.0142770461 46.3370242,0 C45.5984775,0.0214155691 45.0212226,0.586786594 45.0042445,1.3020666 C45.0014148,1.33062069 45.0014148,7.56540672 45.0042445,20.0064247 L45.0042445,36.9375736 L45.0042445,38.3253025 L45.0042445,38.607988 C45,38.6979334 45.0042445,38.719349 45.0042445,38.7364814 C45.0382007,39.4303459 45.602722,39.9828675 46.28609,40 L55.0722491,40 L82.6319262,40 L99.3341176,40 L119.962491,40 L135.675709,40 C136.410012,39.9871507 136.983022,39.4217796 137,38.7107827 L137,19.9935753 Z" />
      <path
        fill={monotone ? monotoneColors.foreground : colors.foreground}
        d="M104,7.55541279 C105.286849,7.5896777 105.930273,7.60681015 107.225875,7.64964129 L107.225875,17.684977 C108.819116,17.7363743 109.61136,17.7749224 111.200224,17.8477353 L111.200224,7.8081165 C112.491449,7.85951387 113.130497,7.88949566 114.421722,7.94945926 L114.421722,31.5793982 C113.130497,31.5151515 112.491449,31.4894528 111.200224,31.4380555 L111.200224,20.7002891 C109.61136,20.6446086 108.819116,20.6060606 107.225875,20.5503801 L107.225875,31.2752971 C105.930273,31.2367491 105.286849,31.2238998 104,31.1853518 L104,7.55541279 Z"
      />
      <path
        fill={monotone ? monotoneColors.foreground : colors.foreground}
        d="M96.4537866,10.2580576 C96.1298859,10.2452083 95.9635586,10.2452083 95.644035,10.236642 C94.6985952,10.236642 94.2258754,10.7977299 94.2258754,11.9413213 L94.2258754,26.4268123 C94.2258754,27.5618375 94.6985952,28.1400578 95.644035,28.148624 C96.5150651,28.1571903 96.9571457,28.1571903 97.8325529,28.1743227 L97.8325529,22.387836 C97.1541123,22.3707035 96.8214576,22.3707035 96.1473941,22.3621373 L96.1473941,19.5053004 C98.1126832,19.5224328 99.0931392,19.5395653 101.058428,19.5781133 L101.058428,31.0954064 C98.5328786,31.0354428 97.2635382,31.0225934 94.7336115,31.005461 C92.2430782,30.9883285 91,29.6605632 91,27.0050327 L91,11.4102152 C91,8.7075704 92.2430782,7.35838955 94.7336115,7.37123889 C95.6177728,7.37980512 96.0642304,7.39265446 96.9571457,7.39265446 C99.6927931,7.42691937 101.058428,8.8317807 101.058428,11.5729736 L101.058428,14.1514081 C99.7672027,14.1171432 99.1237784,14.1042938 97.8325529,14.0957276 L97.8325529,11.9884356 C97.8325529,10.8448442 97.3729641,10.2666238 96.4537866,10.2580576 L96.4537866,10.2580576 Z"
      />
      <path
        fill={monotone ? monotoneColors.foreground : colors.foreground}
        d="M82.939661,28.2085876 C83.4605282,28.2000214 83.7275274,28.1914552 84.2527717,28.1914552 C85.1325559,28.1743227 85.5658825,27.6517828 85.5658825,26.6238355 L85.5658825,7.40122069 C86.857108,7.39265446 87.5049093,7.37123889 88.7961349,7.37123889 L88.7961349,26.5253239 C88.7961349,29.5063711 87.4961552,31.005461 84.8874419,31.0354428 C83.8063141,31.0611414 83.2591846,31.0697077 82.1605486,31.0954064 C79.5824745,31.1425206 78.3000031,29.6819788 78.3000031,26.679516 L78.3000031,7.55541279 C79.5824745,7.51686476 80.2390299,7.49973231 81.5258784,7.47403362 L81.5258784,26.6966485 C81.5258784,27.7245958 81.9985983,28.2300032 82.939661,28.2085876 L82.939661,28.2085876 Z"
      />
      <path
        fill={monotone ? monotoneColors.foreground : colors.foreground}
        d="M68.0976178,7.94945926 C69.3844664,7.88949566 70.0322677,7.85951387 71.3234932,7.8081165 L71.3234932,28.5726523 C73.0961927,28.4998394 73.989108,28.4655745 75.7661845,28.3970447 L75.7661845,31.2538816 C72.6935054,31.3609594 71.1571658,31.4294892 68.0976178,31.5793982 C68.0976178,22.1351322 68.0888638,17.4065746 68.0976178,7.94945926 L68.0976178,7.94945926 Z"
      />
      <path
        fill={monotone ? monotoneColors.foreground : colors.foreground}
        d="M55.2486766,12.870757 C55.2530536,10.1638291 56.5399022,8.72470286 59.1179762,8.54052896 C60.0809241,8.47199914 60.5623981,8.43773423 61.529723,8.38205375 C64.1121741,8.2107292 65.3990226,9.50422957 65.3990226,12.1854588 L65.3990226,31.7807046 C64.1121741,31.8492344 63.4599957,31.8877824 62.1687702,31.9605954 L62.1687702,23.3087054 C60.6937091,23.407217 59.9539901,23.4500482 58.470175,23.5614091 L58.470175,32.209016 C57.1833264,32.3032445 56.5399022,32.3589249 55.2530536,32.4488703 C55.2530536,24.6236214 55.2486766,20.7002891 55.2486766,12.870757 L55.2486766,12.870757 Z"
      />
      <path d="M62.1700833,12.5833601 C62.1700833,11.6624906 61.7017405,11.2298961 60.7563007,11.2855766 C60.4105149,11.3241246 60.2310564,11.3241246 59.8852706,11.3541064 C58.9442079,11.41407 58.4714881,11.9066281 58.4714881,12.8360638 L58.4714881,20.6955777 C59.9553032,20.5842167 60.6950222,20.5371025 62.1700833,20.442874 L62.1700833,12.5833601 Z" />
      <path
        fill={monotone ? monotoneColors.foreground : colors.foreground}
        d="M127.141592,32.4488703 C125.854743,32.3589249 125.211319,32.3032445 123.920094,32.209016 L123.920094,23.5614091 C122.440655,23.4500482 121.700936,23.407217 120.225875,23.3087054 L120.225875,31.9605954 C118.939027,31.8877824 118.286849,31.8492344 117,31.7807046 L117,12.1854588 C117,9.50422957 118.286849,8.2107292 120.864923,8.38205375 C121.82787,8.43773423 122.318098,8.47199914 123.281046,8.54052896 C125.854743,8.72470286 127.141592,10.1638291 127.141592,12.870757 L127.141592,32.4488703 Z"
      />
      <path d="M122.510439,11.3553914 C122.160276,11.3211265 121.985195,11.3125602 121.635032,11.2868615 C120.698346,11.2311811 120.230003,11.6637756 120.230003,12.584645 L120.230003,20.4441589 C121.700687,20.5383874 122.440406,20.5812185 123.919845,20.6925795 L123.919845,12.8330656 C123.919845,11.9036299 123.451502,11.4110718 122.510439,11.3553914" />
      <path
        fill={monotone ? monotoneColors.shadow : colors.shadow}
        d="M14.1904398,29.0477504 C17.5528062,27.0560799 22.799147,23.9202114 29.9294623,19.6401448 L40,33.1831106 C40,34.9395871 40,36.6706696 40,38.376358 C40,38.6317139 40.0176086,38.8759155 39.9099579,39.1366119 C39.8444214,39.3250427 39.7156219,39.4932251 39.6336975,39.5780182 C39.4063212,39.8196462 39.1091715,39.9603068 38.7422485,40 L22,40 C20.3876636,37.6927774 17.7844768,34.0420276 14.1904398,29.0477504 Z"
      />
      <path
        fill={monotone ? monotoneColors.foreground : colors.foreground}
        d="M15.4603333,10.6279356 C15.1658791,10.4573548 14.7918428,10.4573548 14.5013678,10.6279356 C14.1909973,10.8151585 14.0039791,11.1563201 14.0039791,11.5058028 L14,28.484836 C14,28.8426397 14.1790599,29.1754804 14.4814723,29.3668637 C14.6326784,29.4500739 14.799801,29.5 14.9629445,29.5 C15.1340463,29.5 15.305148,29.4500739 15.452375,29.3668637 L29.5145486,20.8919089 C29.8129818,20.7005255 30,20.3676849 30,20.0057207 C30,19.647917 29.8129818,19.3067554 29.5145486,19.123693 L15.4603333,10.6279356 Z"
      />
    </g>
  </svg>
);

export default Logo;
